
import { defineComponent } from 'vue';
import {
  DmTile,
  DmButton,
  DmFileUploader,
  DmAsyncContent
} from '@dobrymechanik/vue-ui';
import {
  AppModal,
  AppModalBody,
  AppModalHeader
} from '@/components';
import { ImageProcessor } from '@/helpers/image-processor';
import { URI_GARAGE_LOGO } from '@/api/endpoints';
import { mapState, mapMutations } from 'vuex';
import { SET_LOGO } from '@/store/garage/mutations';

export default defineComponent({
  name: 'SettingsLogo',
  components: {
    DmTile,
    DmButton,
    DmFileUploader,
    DmAsyncContent,
    AppModal,
    AppModalBody,
    AppModalHeader
  },
  data () {
    return {
      isModalShown: false as boolean,
      isFileUploaded: false as boolean,
      url: '' as string,
      imageName: '' as string,
      imageProcessor: new ImageProcessor({
        maxWidth: 300,
        maxHeight: 100
      }) as ImageProcessor,
      acceptedFileExtensions: [
        '.jpg',
        '.jpeg',
        '.png'
      ] as string[],
      isLoading: false as boolean,
      isError: false as boolean
    };
  },
  computed: {
    ...mapState('garage', ['logo'])
  },
  methods: {
    ...mapMutations('garage', {
      setLogo: SET_LOGO
    }),
    openModal (): void {
      this.isModalShown = true;
    },
    closeModal (): void {
      this.isModalShown = false;
    },
    async upload (file: File): Promise<void> {
      this.isFileUploaded = true;
      this.imageName = file.name;
      const url = URL.createObjectURL(file);
      this.url = await this.imageProcessor.init(url);
    },
    change (): void {
      this.isFileUploaded = false;
      this.url = '';
    },
    async save (): Promise<void> {
      this.isLoading = true;
      this.isError = false;
      try {
        const formData: FormData = new FormData();
        formData.append('logo', this.imageProcessor.dataURLtoFile(this.url, this.imageName));
        const response = (await this.$http.post<{logo: string}>(URI_GARAGE_LOGO, formData)).data;
        this.setLogo(response.logo);
        this.isModalShown = false;
      } catch (e) {
        this.isError = true;
      }
      this.isLoading = false;
    }
  }
});
