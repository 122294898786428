import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    padding: "md",
    rounded: "",
    bordered: "",
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode("h3", {
        class: [_ctx.$bem({e: 'header'}), "has-margin-bottom-md"]
      }, _toDisplayString(_ctx.$t('settings.files.title')), 3),
      _createVNode("div", {
        class: _ctx.$bem({e: 'list'})
      }, [
        _createVNode(_component_DmButton, {
          block: "",
          bordered: "",
          rounded: "",
          hoverable: "",
          href: "/docs/klauzula_rodo.pdf",
          "new-window": "",
          class: _ctx.$bem({e: 'list-item'})
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('settings.files.gdpr')), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}