
import { defineComponent } from 'vue';
import {
  DmTile,
  DmButton
} from '@dobrymechanik/vue-ui';

export default defineComponent({
  name: 'SettingsFiles',
  components: {
    DmTile,
    DmButton
  }
});
