import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_DmField = _resolveComponent("DmField")
  const _component_DmGridItem = _resolveComponent("DmGridItem")
  const _component_DmPhoneInput = _resolveComponent("DmPhoneInput")
  const _component_DmGrid = _resolveComponent("DmGrid")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmForm = _resolveComponent("DmForm")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    padding: "md",
    rounded: "",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DmAsyncContent, {
        loading: _ctx.isLoading,
        error: _ctx.isError,
        reloadable: "",
        onReload: _ctx.fetchGarageData
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DmForm, {
            "initial-values": _ctx.initialValues,
            "validation-schema": _ctx.validationSchema,
            onSubmit: _ctx.saveGarageData
          }, {
            default: _withCtx(({ errors, resetForm, values }) => [
              _createVNode("h3", {
                class: [_ctx.$bem({e: 'section-header'}), "has-margin-bottom-md"]
              }, _toDisplayString(_ctx.$t('settings.garage.title')), 3),
              _createVNode(_component_DmGrid, {
                "spacings-size": "sm",
                class: "has-margin-bottom-md"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "garageAddressName" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.garageAddressName,
                            "data-test": "field-garage-name",
                            label: _ctx.$t('settings.garage.name')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "customerServiceEmail" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.customerServiceEmail,
                            "data-test": "field-garage-email",
                            label: _ctx.$t('settings.garage.email')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "customerServicePhone" }, {
                        default: _withCtx(({ validate }) => [
                          _createVNode(_component_DmPhoneInput, {
                            modelValue: values.customerServicePhone,
                            "onUpdate:modelValue": ($event: any) => (values.customerServicePhone = $event),
                            valid: !errors.customerServicePhone,
                            light: "",
                            rounded: "",
                            "error-text": errors.customerServicePhone,
                            "data-test": "field-garage-phone",
                            label: _ctx.$t('settings.garage.phone'),
                            onValidate: ($event: any) => {_ctx.isCustomerServicePhoneValid = $event; validate();},
                            onBlur: validate
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "valid", "error-text", "label", "onValidate", "onBlur"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "garageAddressAddress" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.garageAddressAddress,
                            "data-test": "field-garage-address",
                            label: _ctx.$t('settings.garage.address')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "garageAddressPostalCode" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            error: errors.garageAddressPostalCode,
                            "data-test": "field-garage-zip-code",
                            label: _ctx.$t('settings.garage.zipCode')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "garageAddressCity" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.garageAddressCity,
                            "data-test": "field-garage-city",
                            label: _ctx.$t('settings.garage.city')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode("h3", {
                class: [_ctx.$bem({e: 'section-header'}), "has-margin-bottom-md"]
              }, _toDisplayString(_ctx.$t('settings.company.title')), 3),
              _createVNode(_component_DmGrid, {
                "spacings-size": "sm",
                class: "has-margin-bottom-md"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "companyAddressName" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.companyAddressName,
                            "data-test": "field-company-name",
                            label: _ctx.$t('settings.company.name')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "billingEmail" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.billingEmail,
                            "data-test": "field-company-email",
                            label: _ctx.$t('settings.company.email')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "billingPhone" }, {
                        default: _withCtx(({ validate }) => [
                          _createVNode(_component_DmPhoneInput, {
                            modelValue: values.billingPhone,
                            "onUpdate:modelValue": ($event: any) => (values.billingPhone = $event),
                            valid: !errors.billingPhone,
                            light: "",
                            rounded: "",
                            "error-text": errors.customerServicePhone,
                            "data-test": "field-company-phone",
                            label: _ctx.$t('settings.company.phone'),
                            onValidate: ($event: any) => {_ctx.isBillingPhoneValid = $event; validate();},
                            onBlur: validate
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "valid", "error-text", "label", "onValidate", "onBlur"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "companyAddressAddress" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.companyAddressAddress,
                            "data-test": "field-company-address",
                            label: _ctx.$t('settings.company.address')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "companyAddressPostalCode" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            error: errors.companyAddressPostalCode,
                            "data-test": "field-company-zip-code",
                            label: _ctx.$t('settings.company.zipCode')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "companyAddressCity" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            maxlength: 128,
                            error: errors.companyAddressCity,
                            "data-test": "field-company-city",
                            label: _ctx.$t('settings.company.city')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_DmGridItem, {
                    "cols-md": 6,
                    "cols-lg": 4
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DmField, { name: "nip" }, {
                        default: _withCtx(({ field }) => [
                          _createVNode(_component_DmInput, _mergeProps({
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => (field.value = $event)
                          }, field, {
                            light: "",
                            rounded: "",
                            error: errors.nip,
                            "data-test": "field-company-nip",
                            label: _ctx.$t('settings.company.nip')
                          }), null, 16, ["modelValue", "onUpdate:modelValue", "error", "label"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_DmButton, {
                bordered: "",
                rounded: "",
                plain: "",
                color: "primary",
                hoverable: "",
                class: ["has-margin-top-md has-margin-right-md", _ctx.$bem({e: 'restore-button'})],
                onClick: ($event: any) => (resetForm({values: _ctx.initialValues}))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.restore')), 1)
                ]),
                _: 2
              }, 1032, ["class", "onClick"]),
              _createVNode(_component_DmButton, {
                type: "submit",
                color: "primary",
                rounded: "",
                dark: "",
                class: ["has-margin-top-md", _ctx.$bem({e: 'submit-button'})],
                loading: _ctx.isSaving
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.save')), 1)
                ]),
                _: 1
              }, 8, ["class", "loading"])
            ]),
            _: 1
          }, 8, ["initial-values", "validation-schema", "onSubmit"])
        ]),
        _: 1
      }, 8, ["loading", "error", "onReload"])
    ]),
    _: 1
  }))
}