import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsGarage = _resolveComponent("SettingsGarage")
  const _component_DmGridItem = _resolveComponent("DmGridItem")
  const _component_SettingsLogo = _resolveComponent("SettingsLogo")
  const _component_SettingsFiles = _resolveComponent("SettingsFiles")
  const _component_DmGrid = _resolveComponent("DmGrid")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({}),
    "data-test": "settings-page"
  }, {
    default: _withCtx(() => [
      _createVNode("h2", {
        class: [_ctx.$bem({e: 'title'}), "has-margin-bottom-lg"]
      }, _toDisplayString(_ctx.$t('settings.title')), 3),
      _createVNode(_component_DmGrid, { "spacings-size": "md" }, {
        default: _withCtx(() => [
          _createVNode(_component_DmGridItem, {
            "cols-lg": 8,
            class: "has-margin-bottom-md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SettingsGarage, { class: "has-margin-bottom-lg" })
            ]),
            _: 1
          }),
          _createVNode(_component_DmGridItem, {
            "cols-lg": 4,
            class: "has-margin-bottom-md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SettingsLogo, { class: "has-margin-bottom-lg" }),
              _createVNode(_component_SettingsFiles)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}