
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';
import {
  DmGrid,
  DmGridItem
} from '@dobrymechanik/vue-ui';
import {
  SettingsGarage,
  SettingsLogo,
  SettingsFiles
} from './_components';

export default defineComponent({
  name: 'Settings',
  components: {
    AppWrapper,
    DmGrid,
    DmGridItem,
    SettingsGarage,
    SettingsLogo,
    SettingsFiles
  }
});
