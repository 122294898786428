
import { defineComponent } from 'vue';
import {
  DmTile,
  DmInput,
  DmGrid,
  DmGridItem,
  DmAsyncContent,
  DmButton,
  DmPhoneInput
} from '@dobrymechanik/vue-ui';
import {
  Form,
  Field
} from 'vee-validate';
import {
  SettingsGarage
} from '@/api/models';
import { URI_GARAGE } from '@/api/endpoints';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';

export default defineComponent({
  name: 'SettingsGarage',
  components: {
    DmTile,
    DmInput,
    DmGrid,
    DmGridItem,
    DmAsyncContent,
    DmButton,
    DmForm: Form,
    DmField: Field,
    DmPhoneInput
  },
  emits: [],
  data () {
    return {
      initialValues: null as SettingsGarage |null,
      isLoading: true as boolean,
      isError: false as boolean,
      isSaving: false as boolean,
      isCustomerServicePhoneValid: true,
      isBillingPhoneValid: true
    };
  },
  computed: {
    validationSchema () {
      return {
        garageAddressName: (v: string) => !!v || this.$t('settings.garage.validation.name'),
        customerServiceEmail: (v: string) => /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || this.$t('settings.garage.validation.email'),
        customerServicePhone: () => this.isCustomerServicePhoneValid || this.$t('settings.garage.validation.phone'),
        garageAddressAddress: (v: string) => !!v || this.$t('settings.garage.validation.address'),
        garageAddressPostalCode: (v: string) => /^[0-9]{2}-[0-9]{3}$/.test(v) || this.$t('settings.garage.validation.zipCode'),
        garageAddressCity: (v: string) => !!v || this.$t('settings.garage.validation.city'),
        companyAddressName: (v: string) => !!v || this.$t('settings.company.validation.name'),
        nip: (v: string) => /^[0-9]{10}$/.test(v) || this.$t('settings.company.validation.nip'),
        companyAddressPostalCode: (v: string) => /^[0-9]{2}-[0-9]{3}$/.test(v) || this.$t('settings.company.validation.zipCode'),
        companyAddressAddress: (v: string) => !!v || this.$t('settings.company.validation.address'),
        companyAddressCity: (v: string) => !!v || this.$t('settings.company.validation.city'),
        billingEmail: (v: string) => /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || this.$t('settings.company.validation.email'),
        billingPhone: () => this.isBillingPhoneValid || this.$t('settings.company.validation.phone')
      };
    }
  },
  created () {
    this.fetchGarageData();
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    async fetchGarageData (): Promise<void> {
      this.isLoading = true;
      this.isError = false;
      try {
        this.initialValues = (await this.$http.get<SettingsGarage>(URI_GARAGE)).data;
      } catch (e) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    async saveGarageData (values: any): Promise<void> {
      this.isSaving = true;
      try {
        const data = jsonToURLSearchParams(values);
        this.initialValues = (await this.$http.post<SettingsGarage>(URI_GARAGE, data)).data;
        this.createNotification({
          message: this.$t('notifications.changeSuccess'),
          type: 'success'
        });
      } catch (e) {
        this.createNotification({
          message: this.$t('notifications.somethingWentWrong'),
          type: 'error'
        });
      }
      this.isSaving = false;
    }
  }
});
