import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_DmFileUploader = _resolveComponent("DmFileUploader")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_AppModalBody = _resolveComponent("AppModalBody")
  const _component_AppModal = _resolveComponent("AppModal")
  const _component_DmTile = _resolveComponent("DmTile")

  return (_openBlock(), _createBlock(_component_DmTile, {
    color: "white",
    padding: "md",
    rounded: "",
    bordered: "",
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode("h3", {
        class: [_ctx.$bem({e: 'header'}), "has-margin-bottom-md"]
      }, _toDisplayString(_ctx.$t('settings.logo.title')), 3),
      _createVNode("div", {
        class: [_ctx.$bem({e: 'logo-wrapper'}), "has-margin-bottom-md"]
      }, [
        _createVNode("img", {
          src: _ctx.logo,
          class: _ctx.$bem({e: 'logo'})
        }, null, 10, ["src"])
      ], 2),
      _createVNode(_component_DmButton, {
        color: "primary",
        dark: "",
        rounded: "",
        onClick: _ctx.openModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('settings.logo.edit')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_AppModal, {
        modelValue: _ctx.isModalShown,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isModalShown = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppModalHeader, {
            "with-close-button": "",
            "data-test": "event-details-header",
            title: _ctx.$t('settings.logo.uploadTitle'),
            onClose: _ctx.closeModal
          }, null, 8, ["title", "onClose"]),
          _createVNode(_component_AppModalBody, null, {
            default: _withCtx(() => [
              _createVNode(_component_DmAsyncContent, {
                loading: _ctx.isLoading,
                error: _ctx.isError,
                reloadable: "",
                onReload: _ctx.save
              }, {
                default: _withCtx(() => [
                  (!_ctx.isFileUploaded)
                    ? (_openBlock(), _createBlock(_component_DmFileUploader, {
                        key: 0,
                        color: "white",
                        rounded: "",
                        "accepted-extensions": _ctx.acceptedFileExtensions,
                        onUpload: _ctx.upload
                      }, null, 8, ["accepted-extensions", "onUpload"]))
                    : (_openBlock(), _createBlock("div", {
                        key: 1,
                        class: _ctx.$bem({e: 'preview'})
                      }, [
                        _createVNode("figure", {
                          class: [_ctx.$bem({e: 'preview-figure'}), "has-margin-bottom-sm"]
                        }, [
                          _createVNode("caption", {
                            class: [_ctx.$bem({e: 'preview-caption'}), "has-margin-bottom-sm"]
                          }, _toDisplayString(_ctx.$t('settings.logo.preview')) + ": ", 3),
                          _createVNode("div", {
                            class: _ctx.$bem({e: 'logo-wrapper'})
                          }, [
                            _createVNode("img", {
                              src: _ctx.url,
                              alt: "preview",
                              class: _ctx.$bem({e: 'logo'})
                            }, null, 10, ["src"])
                          ], 2)
                        ], 2),
                        _createVNode("div", {
                          class: _ctx.$bem({e: 'preview-buttons'})
                        }, [
                          _createVNode(_component_DmButton, {
                            color: "primary",
                            rounded: "",
                            dark: "",
                            class: _ctx.$bem({e: 'preview-button'}),
                            onClick: _ctx.change
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('settings.logo.change')), 1)
                            ]),
                            _: 1
                          }, 8, ["class", "onClick"]),
                          _createVNode(_component_DmButton, {
                            color: "primary",
                            rounded: "",
                            dark: "",
                            class: _ctx.$bem({e: 'preview-button'}),
                            onClick: _ctx.save
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('common.save')), 1)
                            ]),
                            _: 1
                          }, 8, ["class", "onClick"])
                        ], 2)
                      ], 2))
                ]),
                _: 1
              }, 8, ["loading", "error", "onReload"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["class"]))
}